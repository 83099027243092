import React from 'react';
import { Box, Stack } from '@mui/material';
import ButtonAppBar from './nav';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import processlist from './functions/processlist';
import { Container } from '@mui/system';
import Typography from '@mui/material';
import Button from '@mui/material/Button';
import Maincolumn from './functions/maincolumn';
import Mainsidebar from './functions/mainsidebar';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Mainleftfunctionbar from './functions/mainleftfunctionbar';



const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

const mainHeaderHeading = "Welcome to my REACT Testground 26 JUL"
function MainHeader() {
  const navigate = useNavigate();
  const navigateToAboutUs = () => {
    navigate('/about'); }
  return(
    <div>
      <Box sx={{
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '30vh',
        backgroundColor: '#ECECEC'
      }}>
        <h1 style={{paddingTop:'50px',color:'black'}}>{mainHeaderHeading}</h1>
        <Button onClick={navigateToAboutUs} variant="contained" sx={{backgroundColor:
        "black", color: "white" ,'&:hover': {
          backgroundColor: '#fff',
          color: '#3c52b2',
      }}}>Learn More</Button>
    </Box>
    </div>
  )
}

function IndexPage() {

    return (
        
      <div>
        <ButtonAppBar/>
        <MainHeader/>
        <Box>
        <Grid container spacing={1}>
          <Grid item xs = {12} sm={2}>
          <Mainleftfunctionbar></Mainleftfunctionbar>
          </Grid>
        <Grid item xs={12} sm={7}>
           <Maincolumn></Maincolumn>
           </Grid>
           <Grid item xs={12} sm={3}>
           <Mainsidebar></Mainsidebar>
           </Grid>
        </Grid>       
        </Box>
       </div> );
}
export default IndexPage;