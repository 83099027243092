import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import { BorderBottom, HideImageSharp } from '@mui/icons-material'
import { useState } from 'react'
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider'
import CloseIcon from '@mui/icons-material/Close';
import imagetest from '../media/imagetest.jpg';


var feed = [
    {id:1, feedName:"Rephrasing Stuff Today", description:"rephrasing is a hard work", image:imagetest, isOk:true},
    {id:2, feedName:"Rephrasing Stuff Today2", description:"rephrasing is a hard work2", image:imagetest, isOk:false},
    {id:3, feedName:"Rephrasing Stuff Today3", description:"rephrasing is a hard work3", image:imagetest, isOk:false},
    {id:4, feedName:"Rephrasing Stuff Today4", description:"rephrasing is a hard work4", image:imagetest, isOk:true},
    {id:5, feedName:"Rephrasing Stuff Today5", description:"rephrasing is a hard work5", image:imagetest, isOk:false},
    {id:6, feedName:"Rephrasing Stuff Today6", description:"rephrasing is a hard work6", image:imagetest, isOk:true},
  ]

export default function Mainleftfunctionbar() {

    const [showInput, setShowInput] = useState(false);
    const [showRemove, setShowRemove] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    
  return (
    <div>
        <Box p={2} sx={{backgroundColor:"while",color:"black"}} flex={4} flexDirection="row"><Typography p={2}>Tool Bar</Typography><Divider/>
            <Box sx={{}}>
                
                {showInput || <Button variant='text' sx={{width:"100%"}} onClick={() => setShowInput(true)}>Input Data</Button>}
                {showInput && <div><Box sx={{border:1, marginTop:"10px"}} p={1}><CloseIcon onClick={() => setShowInput(false)} sx={{paddingTop:"10px",alignItems:'end',color: "red" }}/><p><b>Input Data Area</b></p>
                <Box><TextField id="outlined-basic" label="Item Name" variant="outlined" sx={{paddingTop:'10px',paddingBottom:'10px',width:"100%"}}/></Box>
                <Box><TextField
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={2}
                    sx={{paddingTop:'10px',paddingBottom:'10px', width:"100%"}}/></Box><Button variant='outlined' sx={{width:"100%"}} color="secondary">Submit</Button></Box></div>}
                
                {showRemove || <Button variant='text' sx={{width:"100%"}}>Remove Entry</Button>}
                {showEdit || <Button variant='text' sx={{width:"100%"}}>Edit Entry</Button>}
      </Box>
      </Box>
    </div>
  )
}
