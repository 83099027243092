import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CodeOffIcon from '@mui/icons-material/CodeOff';


const defaultSecondary = "Successful"
const iPG = "in Progress"

function generateNew(){
    const progressItemTest = [
        {id:1, pri:"Deploy React to Firebase",sec:defaultSecondary, isOk:true},
        {id:2, pri:"Chaning Layout in Mainpage",sec:defaultSecondary, isOk:true},
        {id:3, pri:"Designing Progress Bar",sec:defaultSecondary, isOk:true},
        {id:4, pri:"Designing Personal Feed Area",sec:defaultSecondary, isOk:true},
        {id:5, pri:"Conditional Rendering for icon in sidebar",sec:defaultSecondary, isOk:true},
        {id:6, pri:"Self Learning Backend system - Axios",sec:iPG, isOk:false},
    ];

    const reversed = progressItemTest.reverse();

    return (
        <div>
            {reversed.map(item => (
                <ListItem>
                <ListItemIcon>
                {item.isOk && <AssignmentTurnedInIcon /> }
                {item.isOk || <CodeOffIcon /> }
                </ListItemIcon>
                <ListItemText
                  primary={item.pri}
                  secondary={item.sec}
                />
          </ListItem>
            ))}
            
            
        </div>
    )
}

var generateNewList = generateNew()

export default function Mainsidebar() {
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

  return (

    <div>
        <Box p={2}  flexDirection="row" sx={{backgroundColor:"white",color:"black"}} flex={2}><Typography p={2}>Progress Bar</Typography> <Divider></Divider>
            <List>
            {generateNewList}
            </List>
        </Box>
    </div>
  )
}
