import React from 'react';
import ButtonAppBar from './nav';
import SignUp from './registerform';

function RegisterPage() {
    
    return (
<div> <ButtonAppBar/> <SignUp/></div>
) }

export default RegisterPage;