import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import imagetest from '../media/imagetest.jpg';
import { alignProperty } from '@mui/material/styles/cssUtils';

const feed = [
  {id:1, feedName:"Rephrasing Stuff Today", description:"rephrasing is a hard work", image:imagetest, isOk:true},
  {id:2, feedName:"Rephrasing Stuff Today2", description:"rephrasing is a hard work2", image:imagetest, isOk:false},
  {id:3, feedName:"Rephrasing Stuff Today3", description:"rephrasing is a hard work3", image:imagetest, isOk:false},
  {id:4, feedName:"Rephrasing Stuff Today4", description:"rephrasing is a hard work4", image:imagetest, isOk:true},
  {id:5, feedName:"Rephrasing Stuff Today5", description:"rephrasing is a hard work5", image:imagetest, isOk:false},
  {id:6, feedName:"Rephrasing Stuff Today6", description:"rephrasing is a hard work6", image:imagetest, isOk:true},
]

function feedGenerate(){

  const reversedFeed = feed.reverse();

  return(
    <div>
      {reversedFeed.map(item => (
        <Box sx={{paddingTop:"50px"}}>
      <Card key={item.id}>
      <CardMedia
        component="img"
        height="200px"
        image={item.image}
        alt="a photo"
      />
      <CardContent sx={{textAlign:"left"}}>
        <Typography gutterBottom variant="h5" component="div">
          {item.feedName}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card></Box>))}
    </div>
  )
}


export default function Maincolumn() {
  var feedGenerateElement = feedGenerate()

  return (
    <div>
      <Box p={2} sx={{backgroundColor:"while",color:"black"}} flex={4} flexDirection="row"><Typography p={2}>Personal Feed Area</Typography><Divider></Divider>
      
     
    {feedGenerateElement}      
      </Box>
    </div>
  )
  
}
