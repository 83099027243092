import React from 'react';
import { Link } from 'react-router-dom';
import ButtonAppBar from './nav';
import SignIn from './loginform';

function LoginPage() {
    
    return (
<div> <ButtonAppBar/> <SignIn/> </div>
) }

export default LoginPage;