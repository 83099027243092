import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

  
const card = (
    <React.Fragment>
        <Container maxWidth="xs">
      <CardContent >
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          User Profile
        </Typography>
        <Typography>
       </Typography>
        <Typography variant="h6" component="div">
          Issac Chan
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Working on React rn
        </Typography>
        <Typography variant="body2">
          idk what to say here but this part is a description.
          <br />
          {'"hello world"'}
        </Typography>
      </CardContent>
     
      </Container>
    </React.Fragment>
  );

export default function OutlinedCard() {
    return (
      <Box sx={{ display: 'inline-block', mx: '10px', transform: 'scale(1.5)' }}>
        <Card variant="outlined" >{card}</Card>
      </Box>
    );
  }