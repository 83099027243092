import React from 'react';
import { Link } from 'react-router-dom';
import ButtonAppBar from './nav';
import OutlinedCard from './functions/profilecard';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';


const mainHeaderHeading = "About Page";
export function MainHeader() {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/'); }
  return(
    <div>
      <Box sx={{
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '30vh',
        backgroundColor: '#ECECEC'
      }}>
        <h1 style={{paddingTop:'50px',color:'black'}}>{mainHeaderHeading}</h1>
        <Button onClick={navigateToHome} variant="contained" sx={{backgroundColor:
        "black", color: "white" ,'&:hover': {
          backgroundColor: '#fff',
          color: '#3c52b2',
      }}}>Return to Index</Button>
    </Box>
    </div>
  )
}

function AboutPage() {
  
    return (
      <div>
<ButtonAppBar/>
<MainHeader></MainHeader>
 <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh',
      }}
    ><OutlinedCard/></div></div>
               

) }

export default AboutPage;